import React, { useState } from "react";
import { Container, Col, Row, ListGroup } from "react-bootstrap";

import "./Services.css";
import { videos } from "./videos";

export default () => {
  const [selectedService, setSelectedService] = useState(null);
  const [selectedServiceIndex, setSelectedServiceIndex] = useState(null);

  const handleServiceClick = (item, index) => {
    setSelectedService(item);
    setSelectedServiceIndex(index);

    if (window.innerWidth <= 768) {
      const serviceNameElement = document.getElementById("serviceName");
      if (serviceNameElement) {
        serviceNameElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <Container fluid="lg">
      <h1>Services</h1>
      <Row className="service-list">
        <Col md={3}>
          <ListGroup>
            {videos.map((video, index) => (
              <ListGroup.Item
                key={video.name}
                action
                onClick={() => handleServiceClick(video, index)}
                active={selectedServiceIndex === index}
              >
                {video.name}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
        <Col md={9}>
          {selectedService && <h4 id="serviceName">{selectedService.name}</h4>}
          <div className="video-player">
            {selectedService &&
              (selectedService.videoName.indexOf("mp4") >= 0 ? (
                <iframe
                  src={`https://www.mediamed.com/partners/SingleVideo.aspx?bgcolor=black&vid=${selectedService.videoName}&size=large`}
                  title="Service Video"
                  width="100%"
                  height="362"
                  allowFullScreen
                />
              ) : (
                <iframe
                  src={`https://www.youtube.com/embed/${selectedService.videoName}`}
                  title="Service Video"
                  width="100%"
                  height="380"
                  allowFullScreen
                />
              ))}
          </div>
          <div className="article-section">
            {selectedService && (
              <div
                dangerouslySetInnerHTML={{ __html: selectedService.htmlText }}
              />
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};
