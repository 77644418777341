import React from "react";
import { Link } from "react-router-dom";
import { Button, Navbar, Nav } from "react-bootstrap";

export default (props) => {
  return (
    <div className="nav-container">
      <Navbar collapseOnSelect expand="lg" className="navigationBar">
        <Navbar.Brand as={Link} to="/">
          <h1 className="accentFont">
            Remington
            <br />
            Park
            <br />
            Dental
          </h1>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          className="ml-auto"
        />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link as={Link} to="/About" className="nav-item">
              Meet Dr. Sheetal
            </Nav.Link>
            <Nav.Link as={Link} to="/Services" className="nav-item">
              Services
            </Nav.Link>
            <Nav.Link as={Link} to="/CaseStudies" className="nav-item">
              Case Studies
            </Nav.Link>
            <Nav.Link as={Link} to="/Testimonials" className="nav-item">
              Testimonials
            </Nav.Link>
            <Nav.Link as={Link} to="/Contact" className="nav-item">
              Contact Us
            </Nav.Link>
          </Nav>
          <Navbar.Collapse className="justify-content-end">
            <a href="tel:4082450600" style={{ marginRight: "20px" }}>
              (408) 245-0600
            </a>
            <Button
              variant="primary"
              href="https://bookit.dentrixascend.com/soe/new/dental?pid=ASC13000000000822&amp;mode=externalLink"
              target="_blank"
            >
              Schedule Now
            </Button>
          </Navbar.Collapse>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};
