import { React } from "react";
import { Button, Col, Row } from "react-bootstrap";
import HomeCarousel from "../HomeCarousel/HomeCarousel";

import { Carousel } from "react-responsive-carousel";

import "./Home.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import DrGokhale from "../../images/drgokhale.jpg";

export default (props) => {
  return (
    <div className="body">
      <article id="hero">
        <Row>
          <Col md={12} lg={6} className="pb-2 my-auto">
            <h2>Dr. Sheetal Gokhale, DDS</h2>
            <h1>Your Family's One-Stop Shop for Dental Health</h1>
            <p>
              With all the treatments you'd expect from a dentist and free
              consultations for anything you can think of, schedule an
              appointment right away!
            </p>
            <Button
              variant="primary"
              href="https://bookit.dentrixascend.com/soe/new/dental?pid=ASC13000000000822&amp;mode=externalLink"
              target="_blank"
            >
              Schedule Now
            </Button>
          </Col>

          <Col md={12} lg={6} className="ml-auto">
            <HomeCarousel />
          </Col>
        </Row>
      </article>
      <article id="welcome">
        <Row className="body-container">
          <Col lg={12} xl={6}>
            <h1>
              Welcome to{" "}
              <span style={{ fontFamily: "Courgette" }}>
                Remington Park Dental!
              </span>
            </h1>
            <p>
              Here, we look forward to providing you with the highest quality
              care in a comfortable atmosphere.
            </p>
            <p>
              We will combine our expertise and the latest in dental
              technologies with our thoroughness and professionalism to make the
              difference.
            </p>
            <p>We hope to see you soon!</p>
            <h3>
              <span style={{ fontFamily: "Courgette", color: "#0a0903" }}>
                Dr. Sheetal Gokhale, DDS
              </span>
            </h3>
          </Col>
          <Col lg={12} xl={6}>
            <img src={DrGokhale} alt="Dr. Gokhale" className="headshot" />
          </Col>
        </Row>
      </article>
      <article id="testimonials">
        <div>
          <Row className="body-container">
            <Col>
              <h1 className="text-center">You're in good hands.</h1>
              <Carousel
                showThumbs={false}
                showStatus={false}
                showIndicators={false}
              >
                <div key="slide1" style={{ width: "75%" }}>
                  <span>&#9733;&#9733;&#9733;&#9733;&#9733;</span>
                  <h3>
                    "Dr. Sheetal is very nice. I visited 2 times for my son's
                    teeth removal and she really took great care of him. She
                    engaged him in various talks etc that he had minimal
                    discomfort in teeth extraction. Also she charges nominal.
                    Other staff members are also very friendly. I highly
                    recommend this practice!"
                  </h3>
                  <h5 style={{ color: "black" }}>Parag Agarwal</h5>
                </div>
                <div key="slide2" style={{ width: "75%" }}>
                  <span>&#9733;&#9733;&#9733;&#9733;&#9733;</span>
                  <h3>
                    "I've had a good experience with Dr. Sheetal Gokhale. She's
                    super-friendly, explains all the procedures clearly, and
                    takes care to minimize pain & inconvenience to her patients.
                    Support staff is welcoming as well, and they accommodates
                    last minute requests if you have any emergencies."
                  </h3>
                  <h5 style={{ color: "black" }}>Samarth Rangavittal</h5>
                </div>
                <div key="slide3" style={{ width: "75%" }}>
                  <span>&#9733;&#9733;&#9733;&#9733;&#9733;</span>
                  <h3>
                    "Dr. Sheetal is an exceptional dentist. She and her staff
                    provide the utmost care and while the doctor is gentle,
                    she's very good at what she does."
                  </h3>
                  <h5 style={{ color: "black" }}>Nitin Budhiraja</h5>
                </div>
              </Carousel>
            </Col>
          </Row>
        </div>
      </article>
      <article id="insurance">
        <Row className="body-container">
          <Col lg={12} xl={6} className="my-auto">
            <h1>We'll take your insurance!</h1>
            <p>
              We accept most major dental insurance providers. Give us a call at{" "}
              <a href="4082450600">(408) 245-0600</a> and we'll confirm your
              coverage.
            </p>
          </Col>
          <Col lg={12} xl={6}>
            <img
              src="https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8"
              alt="Dr. Gokhale"
              className="headshot"
            />
          </Col>
        </Row>
      </article>
      <article id="services">
        <Row className="body-container">
          <Col lg={12} xl={6}>
            <img
              src="https://images.unsplash.com/photo-1564420228450-d9a5bc8d6565?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8"
              alt="Dr. Gokhale"
              className="headshot"
            />
          </Col>
          <Col lg={12} xl={6} className="my-auto">
            <h1>Services</h1>
            <ul>
              <li>General Dentistry</li>
              <li>Cleaning</li>
              <li>Dental Exams</li>
              <li>Wisdom Tooth Extractions</li>
              <li>Cosmetic Dentistry</li>
              <li>Home Teeth Whitening</li>
              <li>Root Canal</li>
            </ul>
            <h3>
              <span style={{ fontFamily: "Courgette" }}>... and more!</span>
            </h3>
          </Col>
        </Row>
      </article>
      {/* <article id="promo">
        <Row className="body-container">
          <Col>
            <h1>
              Get your first visit for just{" "}
              <span style={{ fontFamily: "Courgette" }}>$99*</span>
            </h1>
            <h4>comprehensive exam &middot; dental x-rays &middot; cleaning</h4>
            <p>We'd love to see you!</p>
            <p>
              Schedule an appointment by calling us at{" "}
              <a href="4082450600">(408) 245-0600</a> or by clicking the button
              below!
            </p>
            <Button
              variant="secondary"
              href="https://bookit.dentrixascend.com/soe/new/dental?pid=ASC13000000000822&amp;mode=externalLink"
              target="_blank"
            >
              Schedule Now
            </Button>
            <p>
              <em>* this offer is limited to uninsured patients only</em>
            </p>
          </Col>
        </Row>
      </article> */}
    </div>
  );
};
