import React, { Component } from "react";

import "./Main.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";

// Site Components
import NavigationBar from "../NavigationBar/NavigationBar";
import Footer from "../Footer/Footer";
// Pages
import Home from "../Home/Home";
import About from "../About/About";
import FourZeroFour from "../FourZeroFour";
import Contact from "../Contact/Contact";
import Services from "../Services/Services";
import CaseStudies from "../CaseStudies/CaseStudies";
import Testimonials from "../Testimonials/Testimonials";

const TITLE = "Remington Park Dental | Sheetal Gokhale, DDS | Sunnyvale, CA";

class Main extends Component {
  render() {
    return (
      <div className="full">
        <NavigationBar />
        <div className="content">
          <Switch>
            <Route exact path="/">
              <Helmet>
                <title>{"Home | " + TITLE}</title>
              </Helmet>
              <Home />
            </Route>
            <Route exact path="/About">
              <Helmet>
                <title>{"About | " + TITLE}</title>
              </Helmet>
              <About />
            </Route>
            <Route exact path="/Contact">
              <Helmet>
                <title>{"Contact | " + TITLE}</title>
              </Helmet>
              <Contact />
            </Route>
            <Route exact path="/Services">
              <Helmet>
                <title>{"Services | " + TITLE}</title>
              </Helmet>
              <Services />
            </Route>
            <Route exact path="/Testimonials">
              <Helmet>
                <title>{"Testimonials | " + TITLE}</title>
              </Helmet>
              <Testimonials />
            </Route>
            <Route exact path="/CaseStudies">
              <Helmet>
                <title>{"Case Studies | " + TITLE}</title>
              </Helmet>
              <CaseStudies />
            </Route>
            <Route path="*">
              <Helmet>
                <title>{"404 Error: Page Not Found | " + TITLE}</title>
              </Helmet>
              <FourZeroFour />
            </Route>
          </Switch>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Main;
