import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./components/NavigationBar/NavigationBar.css"
import App from "./App";
import ScrollToTop from "./ScrollToTop";
import { HashRouter } from "react-router-dom";

import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <React.StrictMode>
    <HashRouter basename="/">
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </HashRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
