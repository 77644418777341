import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Footer.css";

export default (props) => {
  return (
    <footer className="footer mt-auto py-3 bg-light">
      <Container>
        <Row>
          <Col md={12} lg={4}>
            <iframe
              title="Google Maps Location"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3171.2524827986995!2d-122.02827598387519!3d37.36020227983695!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fb5d155555555%3A0x6cd4d873687e980b!2sRemington%20Park%20Dental!5e0!3m2!1sen!2sus!4v1660302854449!5m2!1sen!2sus"
              scrolling="no"
              className="map"
            ></iframe>
          </Col>
          <Col md={12} lg={3}>
            <h5>Sheetal Gokhale, DDS</h5>
            <h3>
              Remington
              <br />
              Park
              <br />
              Dental
            </h3>
            <p>
              697 E. Remington Dr. Suite 2
              <br />
              Sunnyvale, CA 94087
              <br />
              Tel <a href="tel:4082450600">(408) 245-0600</a>
              <br />
            </p>
          </Col>

          <Col md={12} lg={3}>
            <ul className="list-unstyled">
              <li>
                <Link className="footer-nav-item" to="/">
                  Home
                </Link>
              </li>
              <li>
                <Link className="footer-nav-item" to="/About">
                  Meet Dr. Sheetal
                </Link>
              </li>
              <li>
                <Link className="footer-nav-item" to="/Services">
                  Services
                </Link>
              </li>
              <li>
                <Link className="footer-nav-item" to="/CaseStudies">
                  Case Studies
                </Link>
              </li>
              <li>
                <Link className="footer-nav-item" to="/Testimonials">
                  Testimonials
                </Link>
              </li>
              <li>
                <Link className="footer-nav-item" to="/Contact">
                  Contact Us
                </Link>
              </li>
            </ul>
          </Col>
          <Col md={12} lg={2}>
            {/* <h5>Review Us</h5> */}
          </Col>
        </Row>
        <Row>
          <small>
            &copy; {new Date().getFullYear()} Remington Park Dental &middot;
            Website built and designed by{" "}
            <a href="https://dishpanda.github.io/">Rahul Deshpande</a> &middot;
            Some images from Unsplash
          </small>
        </Row>
      </Container>
    </footer>
  );
};
