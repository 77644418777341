export const videos = [
  {
    name: "Amalgam Flossings",
    videoName: "AMAL.mp4",
    htmlText: `<h3>What Is A Cavity? &amp; Why Did You Get One?</h3>
    <p>Cavities, as their name implies, are essentially holes in your teeth caused by decay.
        You can sometimes spot a cavity, as the area surrounding the hole generally looks dark brown or gray.</p>
    <p>The main culprits to blame for your cavity are certain types of bacteria in your mouth.
        These bacteria are contained in plaque and they interact with the carbohydrates and sugars in your food creating
        an acidic environment that dissolves the protective enamel on the outer layer of your tooth. Once the acid
        succeeds in eroding the enamel, your tooth is exposed, leaving just the softer dentin layer, which will
        ultimately cause the formation of a cavity. At this point the decay process rapidly speeds up and spreads deeper
        into the tooth.</p>
    <h3>Why Do I Need A Filling?</h3>
    <p>A filling is necessary to treat your cavity because if left untreated the decay will
        eventually grow and will enter into your nerve canal. And yes, this can be as painful as it sounds. It can also
        lead to more serious problems such as infection or abscess.</p>
    <p>A filling may also be needed to replace or repair an old worn down existing filling or
        to fix a chipped tooth.</p>
    <p>In addition to potential pain and discomfort and possible tooth loss, if you wait to
        have your cavity filled it may end up requiring a more difficult process, such as a root canal, to save your
        tooth. And it could also cost you a lot more money to fix. The bottom line is you should get your cavity filled
        as soon as you can.</p>
    <h3>Amalgam (Silver) Fillings</h3>
    <p>Amalgam is a silver metallic filling that is used mainly to fill cavities in the back
        teeth. It is one of the most commonly used filling materials since it is very strong and lasts a long time.
        Amalgam is made out of a combination of metals such as silver, mercury and tin.</p>
    <p>Once the tooth has been filled the filling is checked to insure the proper alignment
        and shape. At this point any necessary adjustments are made to ensure proper functionality. Following the
        filling you should avoid biting or chewing on the filling for at least 24 hours until it completely hardens.</p>
    <h3>New Amalgam Filling Expectations</h3>
    <p>Following the filling procedure it is very common to experience some discomfort,
        normally at the site of the anesthetic or at the tooth itself. To alleviate the discomfort you can follow your
        dentists recommendation on taking an over the counter pain medication. In addition, you may experience
        sensitivity to cold foods and drinks. This sensitivity should resolve in a few days but if your symptoms persist
        then you should visit your dentist.</p>
    <p>In some cases the decay could be quite deep and close to the nerve of the tooth. In
        these instances the nerve could already be infected with bacteria. Even though a filling has been placed, there
        is still a good chance that the tooth may need to go under root canal therapy to relieve the discomfort.</p>`,
  },
  {
    name: "Brushing & Flossing",
    videoName: "OHI.mp4",
    htmlText: `
    <h3>Dental Home Care</h3>
    <p>No treatment will be effective unless you follow it up with routine and proper home
        care. This means you should brush and floss regularly after every meal and at a minimum twice a day. The key is
        not only to do it regularly but also to do it right. Here's how.</p>
    <h3>Flossing</h3>
    <p>To properly floss you should wind the floss around both of your index fingers and push
        it between each of your teeth in your mouth. Make sure to freely move the floss upwards, downwards and even
        sideways to remove any food particles or plaque that may be lodged between your teeth or under the gums.</p>
    <h3>Brushing</h3>
    <p>Many people these days use an electric toothbrush such as a Sonicare. Whether you use
        an electric or still prefer a manual brush you should hold the brush at a 45-degree angle to your teeth. Gently
        but thoroughly rotate the bristles across your teeth and under your gums with a backward and forward motion. Be
        sure to also get the top surfaces of your teeth where you bite or chew on food. Your tongue can also be cleaned
        with your toothbrush, which will help reduce bad breath. Lastly it is important to brush the inside surfaces of
        your teeth with the same rotating motion.</p>
    <p>When brushing, it is important to use toothpaste that contains fluoride. Fluoride helps
        harden the outer layer of the tooth, called enamel, which will prevent cavities and also reduce bacteria in the
        mouth. It can also reverse the formation of smaller cavities.</p>
    <p>Lastly, antibacterial rinses can also help reduce bacteria in the mouth. Please check
        with your dentist or dental hygienist if you have any additional questions.</p>`,
  },
  {
    name: "Cleaning",
    videoName: "SRP.mp4",
  },
  {
    name: "Composite Filling",
    videoName: "COMP.mp4",
  },
  {
    name: "Cosmetic Dentistry",
    videoName: "CRWNPORC.mp4",
  },
  {
    name: "Crown",
    videoName: "CROWN.mp4",
    htmlText: `<p>As you age, your teeth age too. Decay, fillings, trauma or even just normal chewing
    habits can make your teeth break down over time. A dental crown or a cap is a cover that is placed over a tooth
    to protect a weakened tooth from further fracturing or deteriorating. A crown could also be used to replace a
    discolored or badly shaped tooth or protect a tooth that has had a root canal. Lastly, a new crown is necessary
    to simply replace an old worn down or defective crown.</p>
<h3>The Dental Crown Treatment</h3>
<p>It normally takes two visits for your dentist to complete your crown. On your first
    visit your dentist will shape and prepare the tooth so the crown can properly fit over it.<br> Next, an impression
    of the tooth is taken and a temporary crown is inserted to
    protect the prepared tooth. This impression is then sent to a dental laboratory where a custom-fit permanent
    crown is made.<br> On your second visit, the permanent crown is placed. If the fit,
    shape and color of the crown are satisfactory your dentist will proceed with permanently cementing it into
    place.</p>
<h3>Dental Crown Post Treatment Expectations</h3>
<p>After the successful completion of the crown it is not uncommon to feel some
    discomfort, mild pain when you chew, or sensitivity to temperature changes for a few days. Also, if your newly
    cemented crown feels too tall or your bite doesn't feel right, you should contact your dentist.</p>
<h3>Cleaning Your Dental Crown</h3>
<p>Please keep in mind that the longevity of your crown depends in large part on the
    materials in the crown, as well as your oral hygiene, diet and the health of the supporting tooth and gums. It
    is important to maintain all of your teeth with proper brushing and flossing.</p>`,
  },
  {
    name: "Crown Material Composition",
    videoName: "CRWNCMPR.mp4",
    htmlText: `
    <p>The longevity and durability of your crown is directly related to the materials
        selected to make the crown. So let's take a look at different options.</p>
    <h3>Metal Crowns</h3>
    <p>The more precious metal or gold content in the metal the better the fit and better for
        the health of gums around the crown. Keep in mind fit is an important factor in sealing out the bacteria that
        tries to get between the crown and the tooth, possibly causing decay and premature failure of the crown.</p>
    <h3>Porcelain Crowns</h3>
    <p>All porcelain crowns are generally considered the most aesthetic choice since they
        closely resemble the color and appearance of your teeth. Porcelain continues to improve and in fact there are
        newly developed porcelain materials, such as zirconium and empress 2 that can better withstand the forces of
        chewing. Porcelain crowns can give you the Hollywood smile that you are looking for.</p>
    <h3>Porcelain Fused to Metal Crowns</h3>
    <p>The third kind of crown has a porcelain exterior with a metal framework supporting it
        underneath. The porcelain gives you the desired aesthetic, and the metal framework provides better structural
        support making the porcelain much stronger. This type of porcelain-metal hybrid crown can be used to replace
        missing teeth in any area of the mouth, front or back. Again, as a rule, the more precious metal or gold content
        in the metal, the better the fit and better for the health of gums around the crown. An example of this great
        type of crown would be the “captek” crown.</p>`,
  },
  {
    name: "Deep Cleaning",
    videoName: "Wk_fE5fGf_M",
  },
  {
    name: "Dental Bridge",
    videoName: "BRIDGE.mp4",
    htmlText: `
    <p>Dental bridges are used to replace one or more missing teeth in your mouth. Dental
        bridges are fixed, in that they are cemented on the supporting teeth, or in some cases fixed on the dental
        implant that has been placed next to the missing teeth.</p>
    <h3>Importance of Replacing the Missing Teeth</h3>
    <p>Replacing the missing teeth will restore your ability to properly chew and speak
        besides its cosmatic advantage; in addition the space caused by the missing teeth may allow the surrounding
        teeth to shift into that space. This shifting could cause a misalignment in your bite that can result in
        problems with your jaw joint. These shifted teeth are harder to clean, making them more susceptible to gum
        disease, decay or even additional tooth loss.</p>
    <h3>The Dental Bridge Treatment</h3>
    <p>It normally takes two or more visits for your dentist to complete your bridge. On your
        first visit the supporting teeth, which are typically the ones on either side of the missing tooth, are
        prepared. This is done to make room for the crowns that will slide over the supporting teeth. These crowns also
        serve as holders of the pontic, which is the missing tooth's replacement.</p>
    <p>Next, an impression of the supporting teeth is made so a dental laboratory can custom
        fit the bridge. Finally a temporary dental bridge is inserted to protect the supporting teeth as well as the
        space between them.</p>
    <p>On your second visit the permanent bridge is placed and adjusted to insure proper fit
        and function. If the fit of the bridge is satisfactory your dentist will proceed with permanently cementing the
        bridge into place.</p>
    <h3>Dental Bridge Post Treatment Expectations</h3>
    <p>After the successful completion of the bridge you may feel some discomfort, mild pain
        when biting or sensitivity to temperature changes for a few days. As advised by your dentist, you can take
        over-the-counter pain medicine, such as Advil that contains ibuprofen, to alleviate the discomfort. If these
        symptoms persist more than a few days, visit your dentist.</p>
    <h3>Cleaning Your Dental Bridge</h3>
    <p>The longevity of your dental bridge depends in large part on the quality of the
        material in the bridge, as well as your oral hygiene, diet and the health of the supporting teeth and gums.
        Please ask your dentist for proper brushing and flossing techniques.</p>`,
  },
  {
    name: "Dental Filling Materials",
    videoName: "CMPAR.mp4",
    htmlText: `
    <h3>Amalgam VS Composite</h3>
    <p>After your tooth is prepared and ready to be filled, it is important to pick the right
        filling material. Your dentist would best advise you on this.</p>
    <p>There are 2 types of fillings:</p>
    <p>1) Amalgam<br> 2) Composite</p>
    <p>The first filling type is amalgam, commonly referred to as silver fillings. Amalgams
        are a mixture of metals such as mercury, silver, copper and tin.</p>
    <p>The second type is a composite filling, which consists of plastic and glass particles
        and closely matches your tooth color.</p>
    <h3>What Type Of Filling Is Right For You?</h3>
    <p>There is no one right filling for everyone. In most cases your best bet is to follow
        your dentist's recommendation. But in order to make an informed decision here is what you should know about each
        type of filling.</p>
    <p>An amalgam filling may have sensitivity to cold foods and drinks after placement for
        few days or even longer. In addition, these silver fillings often discolor the tooth and surrounding gum. You
        should consult with your dentist before choosing amalgam fillings.</p>
    <p>Composite is the filling of choice when it comes to matching your tooth color.
        Composite fillings consist of plastic and glass particles. They have become the most popular filling material
        because in addition to closely resembling your existing tooth color they also solidly bond or glue to the tooth.
        Also, they don't contain mercury or any other metal like silver fillings and there is less likelihood of
        sensitivity to cold food and drinks.</p>`,
  },
  {
    name: "Denture",
    videoName: "CDENT.mp4",
    htmlText: `
    <p>Like many things in life, the importance of something is never truly appreciated until
        it is gone. If you’ve lost your teeth, whether due to injury, tooth decay or gum disease, you surely understand.
        The good news is that you have options to replace them. Dentures can help restore your way of life by helping
        you with day-to-day functions, such as eating and speaking, as well as help restore your confidence by improving
        your appearance and smile.</p>
    <p>Making a denture is a process that usually takes about 6-12 weeks, however this can
        vary from one patient to another.</p>
    <p>It also depends on the type of the denture and the technique your dentist or the
        laboratory technician uses to make the denture.</p>
    <h3>Denture Treatment Process</h3>
    <p>Complete dentures are made using your mouth as a model. First, your dentist will take
        an accurate impression of the upper and lower arches of your mouth. This impression is then sent to the dental
        laboratory.</p>
    <p>At the next visit, your dentist will record the relationship of the arches that best
        resembles your original bite and helps you select the shape and color of the denture teeth and gums. Keep in
        mind the color of everyone’s gums varies. To make a more natural denture your dentist will help you choose the
        shade that best matches your mouth.</p>
    <p>In the subsequent visits your dentist will adjust your bite, test your speech and check
        the appearance and functionality of the denture teeth and gums. After a satisfactory fit and appearance is
        achieved, the denture is then sent back to the laboratory for fabrication.</p>
    <h3>Denture Complications</h3>
    <p>While every effort is made to make a good and functional denture, please keep in mind
        that there is no such thing as a perfect denture. After delivery of the denture, it may require a few adjustment
        visits and some time for you and your new denture to adapt to each other. The most important point to remember
        is that adjusting to your new dentures is a process; it sometimes takes a little time to get used to.</p>
    <p>A new denture can also alter your eating and speaking habits and it may require a bit
        of practicing before you get comfortable.</p>
    <p>Finally, due to differences in the shapes of the jaws and the strong muscle movement of
        the tongue and cheek, a lower denture may be harder to keep in the mouth compared to an upper denture.</p>
    <h3>Different Denture Options</h3>
    <p>There are new advances in making dentures. One such advance is an implant-supported
        denture that stabilizes the denture. This kind of denture requires the placement of implants in your mouth
        before making the denture.</p>`,
  },
  {
    name: "Denture Home Care",
    videoName: "RPOST.mp4",
  },
  {
    name: "Gold Crown",
    videoName: "CRWNMTL.mp4",
  },
  {
    name: "Home Teeth Whitening",
    videoName: "HOMBL.mp4",
    htmlText: `
    <p>Over time, teeth naturally become darker and stained. In addition to natural aging,
        there are other factors that may also discolor your teeth like:</p>
    <p>Smoking<br> Some food and drinks such as coffee &amp; tea<br
           > Some prescription and over the counter drugs such as: (Tetracycline &amp; Iron)
    </p>
    <h3>A Brighter &amp; Whiter Smile</h3>
    <p>One of the most common ways to whiten your teeth is with a home whitening system.
        Simply put, home bleaching involves wearing a custom fit mouth tray that contains a concentrated hydrogen
        peroxide gel which will bleach your teeth to a brighter, whiter smile.</p>
    <h3>Home Teeth Bleaching Treatment</h3>
    <p>During the first visit, your dentist will take an impression of your teeth after making
        sure your teeth are free of plaque and tarter. From this impression a custom mouth tray is made specifically for
        your mouth. On your second visit your dentist will show you how to apply the gel to the bleaching tray and place
        it into your mouth.</p>
    <p>The amount of time you must wear your bleaching trays depends on how badly your teeth
        are stained, as well as the concentration of the bleaching gel used. This process can vary from a few minutes to
        a few hours. As an example, many brands are applied twice a day for 1-2 hours over a 2 week period.</p>
    <h3>How Long Does Teeth Whitening Last?</h3>
    <p>Home whitening can last anywhere from a few months to a few years and the degree of
        whitening changes form one person to another. This in part depends on the original condition of your teeth,
        specifically how stained they were, as well as the strength of the bleaching gel used. Also, in large part it
        depends on your eating, drinking and smoking habits, as no teeth whitening solution will result in a permanent
        color change of your teeth and it won’t prevent future staining.</p>
    <h3>What Are The Risks Associated With Teeth Bleaching?</h3>
    <p>There are some minor risks associated with home whitening systems. The most common side
        effect is teeth sensitivity. Teeth sensitivity is generally mild and will subside most of the time once the
        treatment is completed. If you feel severe sensitivity you should wear the mouth tray for shorter periods of
        time or consider stopping the treatment entirely.</p>
    <p>Gum irritation or discoloration of the gum is another possible side effect of the home
        whitening procedure. This is mainly due to over loading the mouth tray. Simply stopping the process for a few
        days should help your gums feel and look normal again.</p>
    <h3>Fluoride And Teeth Sensitivity</h3>
    <p>Brushing your teeth with highly concentrated fluoride toothpaste or desensitizing
        toothpaste, such as ‘Sensodyne’ will also help reduce the discomfort. Your dentist can also prescribe a fluoride
        gel that can be used in your bleaching tray which also should help reduce the sensitivity.</p>
    <p>If you have any additional questions, please consult with your dentist or get a more
        visual experience through the MediaMed system.</p>`,
  },
  {
    name: "Immediate Denture",
    videoName: "IDENT.mp4",
    htmlText: `
    <p>If you’ve suffered severe tooth decay, injury, or gum disease and need your remaining
        teeth replaced, an immediate denture can help relieve you of some concerns you may have after the extraction
        process is complete. An immediate denture, as its name implies, is a denture that is placed in your mouth
        immediately after your teeth are removed. It makes the transition to dentures less noticeable and also helps
        keep you performing everyday functions, like chewing and speaking.</p>
    <h3>Immediate Denture Treatment</h3>
    <p>Immediate dentures are made using your mouth as a model. First, your dentist will take
        an accurate impression of the upper and lower arches of your mouth and establish a bite that best resembles your
        original bite. Your dentist will also help you select the shape and color of the denture teeth and gums. This
        impression is then sent to the dental laboratory.</p>
    <p>During the next visit your dentist will adjust your bite, test your speech and check
        the appearance and functionality of the denture teeth and gums. Sometimes it is necessary to repeat this step to
        ensure that everything is just right.</p>
    <p>After a satisfactory fit and appearance are achieved, the denture is then sent back to
        the laboratory for fabrication. At the subsequent visit, the remaining teeth will be removed and the denture
        will be delivered. Please note that the extractions may be performed at one visit or they may be removed in two
        or more visits depending on the number and condition of the teeth to be extracted, the shape of your jaws and
        your health condition. Your dentist will best advise you of the preferred timing for your extractions.</p>
    <h3>Immediate Denture Complications</h3>
    <p>While every effort is made to make a good and functional denture, please keep in mind
        that there is no such a thing as a perfect denture. After delivery of the immediate denture, it may require a
        few adjustment visits and some time for you and your immediate denture to adapt to each other. This is due to
        the fact that when your gums heal following the extractions they will shrink for a period of about 6 months and
        the denture needs to be re-based or re-lined to fit properly.</p>
    <p>The most important point to remember is that adjusting to your immediate dentures is a
        process; in some cases, it takes weeks or months to get used to your immediate denture.</p>
    <p>An immediate denture can also alter your eating; you will not have the same chewing
        efficiency as you had with your natural teeth. An immediate denture will also alter your speaking and it may
        require a bit of practicing before you get comfortable. Keep in mind that due to differences in the shapes of
        the jaws and the strong muscle movements of the tongue and cheek, a lower denture may be harder to keep in the
        mouth compared to an upper denture.</p>
    <p>Fortunately there are new alternatives now, such as implants, which can help restore
        functionality that is more like natural teeth. You can discuss this possibility with your dentist.</p>`,
  },
  {
    name: "Implant for Crown and Bridge",
    videoName: "IMPCRWN.mp4",
  },
  {
    name: "Implant for Denture",
    videoName: "IMPDENT.mp4",
  },
  {
    name: "In Office Teeth Whitening",
    videoName: "OFCBL.mp4",
  },
  {
    name: "Inlay Filling",
    videoName: "INLAY.mp4",
    htmlText: `
    <p>Inlays are custom fit fillings made in a laboratory. First, the decay is cleaned from
        your tooth and then an impression of the tooth is made and sent to the lab. In the interim a temporary filling
        is used to fill in the space and protect your tooth from fracture or further deterioration. It’s important that
        you avoid chewing or applying any stress to the temporary filling, as it may be fragile.</p>
    <p>When the lab receives the impression from your dentist they will custom make your
        filling for your tooth. There are several types of materials they can use for the inlay.</p>
    <h3>Porcelain Inlays</h3>
    <p>The first type of inlay is porcelain or ceramic material colored to match your tooth.
        In addition to being aesthetically pleasing the other benefits of porcelain or ceramic inlays are that they are
        also quite stain resistant and hard.</p>
    <h3>Metal Inlays</h3>
    <p>The second type of inlay is made of a metal, such as gold. Metallic inlays are well
        known for their durability.</p>
    <h3>Composite Inlays</h3>
    <p>The final type of inlay is made of tooth colored composites. Like the porcelain inlays,
        composites are also very aesthetically pleasing. All three types of inlays are bonded or glued to your teeth
        with a special cement or glue. Keep in mind that you should stay away from eating on the site of the newly
        cemented inlay for at least 24 hours.</p>`,
  },
  {
    name: "New Patient Exam",
    videoName: "AFV.mp4",
    htmlText: `
    <h3>Dental Exam</h3>
    <p>A clinical exam is more commonly referred to as a routine check up. This check up lets
        your dentist essentially take inventory of the overall health of your mouth and teeth and diagnose any potential
        problems you may have.</p>
    <p>A. The first thing your dentist will check during the exam is your face and neck.
        Checking visually, your dentist will look for any abnormalities, such as lumps, bumps or swelling.</p>
    <p>B. Checking inside your mouth is next on the agenda. During this part of the check up
        your dentist will be looking for any abnormalities in the soft tissue, such as discolorations or ulcers on your
        lips, gums, tongue, palate and cheeks. Your dentist will also perform an oral cancer screening and will check
        your bite and your jaw joint for any problems.</p>
    <p>C. Next your dentist will check your gums and jawbone, as they are the foundation for
        your teeth. Your dentist will check them for any signs of gingivitis, gum disease and bone disease.</p>
    <p>D. Checking your teeth comes next. Your dentist will be checking them for cavities and
        other problems. Finally, your dentist will be sure to look specifically at any areas where you may have symptoms
        or concerns.</p>
    <h3>X-rays</h3>
    <p>In most cases a clinical exam by itself is not sufficient to completely diagnose all
        potential problems with your mouth. In fact, the majority of problems with your teeth and the jawbone are not
        visible to the naked eye. That is why x-rays play a key role in allowing a better, and more accurate look at
        what is really going on inside your mouth and below your teeth and gums. By using x-rays your dentist can check
        for any bone loss and determine the severity of the gum disease.</p>
    <p>In addition to revealing any problems that were not visible during the clinical exam
        these initial x-rays will also provide your dentist with a benchmark with which to compare against during your
        future visits.</p>`,
  },
  {
    name: "Night Guard",
    videoName: "NIGHTGRD.mp4",
  },
  {
    name: "Orthodontics",
    videoName: "ZO1cw5ZinlE",
  },
  {
    name: "Partial Denture",
    videoName: "PDENT.mp4",
    htmlText: `
    <p>Partial dentures are replacement teeth for people who have lost one or more of their
        teeth. Partial dentures can be taken in and out of the mouth and consist of a denture base, which closely
        resembles the color of your gums and denture teeth, which are attached to a supporting framework. The partial
        denture then attaches to the existing teeth via a clasp or some other retentive device.</p>
    <p>Partial dentures are made using a model of your mouth.<br>
        Making a partial denture requires about 6-8 weeks, however this can vary from one patient to another. It also
        could depend on the type of denture and the technique your dentist or the laboratory technician uses.</p>
    <h3>Denture Treatment</h3>
    <p>The first step in making a partial denture is the preparation of the teeth. During this
        phase your dentist may prepare the teeth that the partial denture will use for support. Next, your dentist will
        take an accurate impression of the upper and lower arches of your mouth and records your bite. The impressions
        are then sent to the dental laboratory.</p>
    <p>At the subsequent visits your dentist will evaluate your bite, test your speech and
        check the appearance and function of the partial denture teeth and gums.</p>
    <p> After the final satisfactory fit and appearance are achieved, the denture is then sent
        back to the laboratory for final fabrication.</p>
    <h3>Partial Denture Complication</h3>
    <p>While every effort is made to make a good and functional partial denture, it may
        require a few adjustment visits and a little time for you and your partial denture to adapt to each other. The
        most important point to remember is that adjusting to your partial denture is a process; in some cases, it takes
        weeks to get used to a partial denture.</p>
    <p>A new partial denture can also alter your eating and speaking habits and it may require
        a bit of practicing before you get comfortable.</p>
    <h3>Different Types Of Partial Dentures</h3>
    <p>There are newly developed techniques in making partial dentures. One such advance is an
        implant-supporting partial denture that helps give additional support to the partial denture. While it offers
        additional support it also requires the placement of implants in your mouth before making the denture.</p>
    <p>There is also a partial denture that uses a special material called valplast which is
        more aesthetically pleasing to the eye. This kind of partial does not use metal as its base and has hooks that
        are made with a flexible plastic material.</p>`,
  },
  {
    name: "Pediatric First Visit",
    videoName: "PEDO.mp4",
  },
  {
    name: "Pediatric Teeth Cleaning",
    videoName: "PEDOPROPHY.mp4",
    htmlText: `
    <h3>Office Cleaning</h3>
    <p>Your dentist or a certified staff member will use what’s called a rotating toothbrush
        to clean the plaque from your teeth. After your teeth have been brushed your dentist may floss to remove any and
        all remaining plaque between your teeth. If necessary, to help harden your teeth and help fight future cavities,
        your dentist or dental staff member may apply a fluoride foam, solution or paint.</p>
    <h3>Home Care</h3>
    <p>Obviously you can’t go to the dentist everyday to have your teeth cleaned, so here are
        some tips on how you can keep your teeth strong and healthy at home. First, you should start by flossing your
        teeth to remove any food particles and plaque that may get stuck between them. The right way to floss is to wind
        the floss around both of your index fingers and gently push it in between each of your teeth in your mouth. Make
        sure to freely move the floss upwards, downwards and even sideways to get out any food that might be stuck.</p>
    <p>After flossing you should then brush your teeth. You should put a small amount of
        toothpaste on a soft toothbrush and hold the toothbrush at a 45-degree angle to your teeth. Then gently rotate
        the bristles across your teeth and under your gums with a backward and forward circular motion. Be sure to also
        get the top surfaces of your teeth where you chew. Lastly, it’s important to brush the inside surfaces of your
        teeth with the same rotating motion.</p>`,
  },
  {
    name: "Porcelain Crown",
    videoName: "CRWNPORC.mp4",
  },
  {
    name: "Porcelain Inlay",
    videoName: "INLAYPORC.mp4",
  },
  {
    name: "Post Surgery Instructions",
    videoName: "POSTOP.mp4",
    htmlText: `<p>Now that the extraction is completed, here's some advice to help you with the
    healing process.</p>
<ol>
    <li>
        <h3>PUT PRESSURE ON THE WOUND</h3>
        <p>First, to control any bleeding put constant pressure on the wound by biting on the
            gauze. Also, change the gauze every few minutes for about a couple of hours. A cold compress or ice packs
            can
            also help this process.</p>
    </li>
    <li>
        <h3>STAY ON A SOFT DIET</h3>
        <p>For the first few days after the extraction you should stay on a soft diet. This
            decreases the chances that you will disturb the site of surgery. After a few days it is generally okay to
            resume
            your normal diet.</p>
    </li>
    <li>
        <h3>TAKE PAIN MEDICINE</h3>
        <p>Following the extraction, it is very common to experience some discomfort, normally at
            the site of the anesthetic or the extraction site itself. To alleviate the discomfort you should follow your
            dentist’s recommendation on taking an over the counter pain medication. When necessary, your dentist may
            decide
            to prescribe a stronger pain medicine to manage higher levels of pain.</p>
    </li>
    <li>
        <h3>VISIT YOUR DENTIST</h3>
        <p>Visit your dentist for a checkup one week after the surgery so your dentist can remove
            any suture and examine the wound to make sure it is healing properly, unless you have complications in which
            you
            need to visit your dentist sooner.</p>
    </li>
    <li>
        <h3>DO NOT DISTURB THE SITE OF THE EXTRACTION</h3>
        <p>Make sure that you do not disturb the extraction site. This means don’t chew, rinse or
            brush directly on the site for at least 24 to 48 hours.</p>
    </li>
    <li>
        <h3>DO NOT SPIT OR SUCK THROUGH A STRAW</h3>
        <p>This is important because using a straw often causes suction that can remove the blood
            clot. Loss of the blood clot causes bleeding or a painful condition called dry socket.</p>
    </li>
    <li>
        <h3>DO NOT SMOKE</h3>
        <p>It is important that you do not smoke for at least 24 hours following the extraction,
            as smoking promotes the likelihood of bleeding and further delays the healing. Smoking could also cause dry
            socket.</p>
    </li>
</ol>

<h3>Potential Outcome After Extracting A Tooth</h3>
<p>A little bleeding, swelling and discomfort is normal after a tooth extraction, however,
    if you have any unusual bleeding beyond 4-6 hours post-operation it is important that you contact your dentist
    immediately or seek assistance at your local emergency room if you are unable to reach your dentist.</p>
<p>Sometimes you may experience discomfort when opening your mouth. This is due to muscle
    spasm or trismus, which usually heals on its own in a few days.</p>`,
  },
  {
    name: "Root Canal",
    videoName: "RCT.mp4",
    htmlText: `<p>A root canal is a treatment used to repair and save a tooth that is badly decayed or becomes infected. During a root canal procedure, the nerve and pulp are removed and the inside of the tooth is cleaned and sealed. Without treatment, the tissue surrounding the tooth will become infected and abscesses may form.</p>
    <p>“Root canal” is the term used to describe the natural cavity within the center of the tooth. The pulp or pulp chamber is the soft area within the root canal. The tooth's nerve lies within the root canal.</p>
    <p>A tooth's nerve is not vitally important to a tooth's health and function after the tooth has emerged through the gums. Its only function is sensory — to provide the sensation of hot or cold. The presence or absence of a nerve will not affect the day-to-day functioning of the tooth.</p>
    <h3>Why Does Tooth Pulp Need to Be Removed?</h3>
    <p>When a tooth's nerve tissue or pulp is damaged, it breaks down and bacteria begin to multiply within the pulp chamber. The bacteria and other decayed debris can cause an infection or abscessed tooth. An abscess is a pus-filled pocket that forms at the end of the roots of the tooth. An abscess occurs when the infection spreads all the way past the ends of the roots of the tooth. In addition to an abscess, an infection in the root canal of a tooth can cause:</p>
    <ul>
    <li>Swelling that may spread to other areas of the face, neck, or head</li>
    <li>Bone loss around the tip of the root</li>
    <li>Drainage problems extending outward from the root. A hole can occur through the side of the tooth with drainage into the gums or through the cheek with drainage into the skin.</li>
    </ul>
    <h3>What Damages a Tooth's Nerve and Pulp in the First Place?</h3>
    <p>A tooth's nerve and pulp can become irritated, inflamed, and infected due to deep decay, repeated dental procedures on a tooth, and/or large fillings, a crack or chip in the tooth, or trauma to the face.</p>
    <h3>What Are the Signs that a Root Canal Is Needed?</h3>
    <p>Sometimes no symptoms are present; however, signs you may need a root canal include:</p>
    <ul>
    <li>Severe toothache pain upon chewing or application of pressure</li>
    <li>Prolonged sensitivity/pain to heat or cold temperatures (after the hot or cold has been removed)
    </li>
    <li>Discoloration (a darkening) of the tooth</li>
    <li>Swelling and tenderness in the nearby gums</li>
    <li>A persistent or recurring pimple on the gums</li>
    </ul>
    <h3>What Happens During a Root Canal?</h3>
    <p>A root canal requires one or more office visits and can be performed by a dentist or endodontist. An endodontist is a dentist who specializes in the causes, diagnosis, prevention, and treatment of diseases and injuries of the human dental pulp or the nerve of the tooth. The choice of which type of dentist to use depends to some degree on the difficulty of the root canal procedure needed in your particular tooth and the general dentist's comfort level in working on your tooth. Your dentist will discuss who might be best suited to perform the work in your particular case.</p>
    <p>The first step in the procedure is to take an X-ray to see the shape of the root canals and determine if there are any signs of infection in a surrounding bone. Your dentist or endodontist will then use local anesthesia to numb the area near the tooth. Anesthesia may not be necessary, since the nerve is dead, but most dentists still anesthetize the area to make the patient more relaxed and at ease.</p>
    <p>Next, to keep the area dry and free of saliva during treatment, your dentist will place a rubber dam (a sheet of rubber) around the tooth.</p>
    <p>An access hole will then be drilled into the tooth. The pulp along with bacteria, the decayed nerve tissue and related debris is removed from the tooth. The cleaning out process is accomplished using root canal files. A series of these files of increasing diameter are each subsequently placed into the access hole and worked down the full length of the tooth to scrape and scrub the sides of the root canals. Water or sodium hypochlorite is used periodically to flush away the debris.</p>
    <p>Once the tooth is thoroughly cleaned, it is sealed. Some dentists like to wait a week before sealing the tooth. For instance, if there is an infection, your dentist may put a medication inside the tooth to clear it up. Others may choose to seal the tooth the same day it is cleaned out. If the root canal is not completed on the same day, a temporary filling is placed in the exterior hole in the tooth to keep out contaminants — like saliva and food — out between appointments.</p>
    <p>At the next appointment, to fill the interior of the tooth, a sealer paste and a rubber compound called gutta percha is placed into the tooth's root canal. To fill the exterior access hole created at the beginning of treatment, a filling is placed.</p>
    <p>The final step may involve further restoration of the tooth. Because a tooth that needs a root canal often is one that has a large filling or extensive decay or other weakness, a crown, crown and post, or other restoration often needs to be placed on the tooth to protect it, prevent it from breaking, and restore it to full function. Your dentist will discuss the need for any additional dental work with you.</p>
    <h3>How Painful Is a Root Canal?</h3>
    <p>Root canal procedures have the reputation of being painful. Actually, most people report that the procedure itself is no more painful than having a filling placed.</p>
    <h3>What Should One Expect After the Root Canal?</h3>
    <p>For the first few days following the completion of a root canal, the tooth may feel sensitive due to natural tissue inflammation, especially if there was pain or infection before the procedure. This sensitivity or discomfort usually can be controlled with over-the-counter pain medications such as ibuprofen(Advil, Motrin), tylenol or naproxen (Aleve). Most patients can return to their normal activities the next day.</p>
    <p>Until your root canal procedure is completely finished — that is to say, the permanent filling is in place and/or the crown, it's wise to minimize chewing on the tooth under repair. This step will help avoid recontamination of the interior of the tooth and also may prevent a fragile tooth from breaking before the tooth can be fully restored.</p>
    <p>As far as oral health care is concerned, brush and floss as you regularly would and see your dentist at normally scheduled intervals.</p>
    <h3>How Successful Are Root Canals?</h3>
    <p>Root canal treatment is highly successful; the procedure has more than a 95% success rate. Many teeth fixed with a root canal can last a lifetime.</p>
    <p>Also, because the final step of the root canal procedure is application of a restoration such as a crown or a filling, it will not be obvious to onlookers that a root canal was performed.</p>
    <h3>Complications of a Root Canal</h3>
    <p>Despite your dentist's best efforts to clean and seal a tooth, new infections might emerge after a root canal. Among the likely reasons for this include:</p>
    <ul>
    <li>More than the normally anticipated number of root canals in a tooth (leaving one of them uncleaned)</li>
    <li>An undetected crack in the root of a tooth</li>
    <li>A defective or inadequate dental restoration that has allowed bacteria to get past the restoration into the inner aspects of the tooth and recontaminate the area</li>
    </ul>
    <p>A breakdown of the inner sealing material over time, allowing bacteria to recontaminate the inner aspects of the tooth. Sometimes retreatment can be successful, other times endodontic surgery must be tried in order to save the tooth. The most common endodontic surgical procedure is an apicoectomy or root-end resection. This procedure relieves the inflammation or infection in the bony area around the end of your tooth that continues after endodontic treatment. In this procedure, the gum tissue is opened, the infected tissue is removed, and sometimes the very end of the root is removed. A small filling may be placed to seal the root canal.</p>
    <h3>Cost of a Root Canal</h3>
    <p>The cost varies depending on how severe the problem is and the tooth affected. Many dental insurance policies cover endodontic treatment. A ballpark estimate for the root canal treatment itself (not including a dental restoration following the procedure) performed by a general dentist could range from $350 to $540 for an incisor and $520 to $800 for a molar. The fees charged by endodontists could be up to 50% higher.</p>
    <h3>Alternatives to a Root Canal</h3>
    <p>Saving your natural teeth is the very best option, if possible. Your natural teeth allow you to eat a wide variety of foods necessary to maintain proper nutrition. The root canal procedure is the treatment of choice.</p>
    <p>The only alternative to a root canal procedure is having the tooth extracted and replaced with a bridge, implant, or removable partial denture to restore chewing function and prevent adjacent teeth from shifting. These alternatives not only are more expensive than a root canal procedure but require more treatment time and additional procedures to adjacent teeth and supporting tissues.</p>
    <h3>Root Canal Prevention</h3>
    <p>Since some of the reasons why the nerve of a tooth and its pulp become inflamed and infected are due to deep decay, repeated dental procedures on a tooth and/or large fillings, following good oral hygiene practices (brushing twice a day, flossing at least once a day, and scheduling regular dental visits) may reduce the need for a root canal procedure. Trauma resulting from a sports-related injury can be reduced by wearing a mouth guard.</p>`,
  },
  {
    name: "Root Canal Treatment Options",
    videoName: "RCT2.mp4",
    htmlText: `<p><strong>Root canal therapy refers to a treatment in which your dentist removes the bacteria infection that has entered your tooth's pulp or nerve chamber.</strong></p>
    <p>Why would you need a root canal? There are several reasons why your tooth may become irritated and inflamed, such as: deep decay, big fillings, trauma to the tooth, a chipped tooth or even repeated dental work.</p>
    <p>Once your dentist has identified the infection inside your tooth you may be wondering what other options you have besides getting a root canal. The first is to do nothing. However, if the bacteria are not removed it will work its way to the tip of the root and eventually into your bone. Once it does, it will cause an abscess and infection. Obviously this course of action, or inaction, is not recommended.</p>
    <p>Your second choice is to extract or remove the tooth. Be aware if you simply extract the tooth, the space caused by the missing tooth will cause the surrounding teeth to shift into that space. This shift could cause a miss alignment in your mouth that can result in problems with your jaw. In addition, these shifted teeth are harder to clean, making them more susceptible to gum disease, decay or even additional tooth loss.</p>
    <p>If you do elect to pull the tooth your best plan would be to replace it with a dental appliance such as an implant or a bridge. This of course would add more expense to the treatment in addition to the loss of your tooth.</p>
    <h3>Root Canal Treatment</h3>
    <p>First a local anesthetic is adminstered to numb the tooth so that the procedure is more comfortable. Next, your dentist will place a protective shield or a rubber dam to keep the tooth clean and saliva free.</p>
    <p>Once numbness sets in, an opening is made through the top part of the tooth. Root canal files are used to clean and shape the inside of the canals and prepare the root canal for a final filling material. During the process a cleansing solution is used to help clean out any remaining bacteria or tooth debris. X-rays may also be taken periodically during the procedure to inform your dentist of the ongoing process.</p>
    <p>When the root canal filling is performed, it is generally done by placing a rubber-like material with a sealer that fills the entire length of the root canal. A temporary filling is then placed on the tooth.</p>
    <p>Please keep in mind that root canal therapy treats the diseased pulp of the tooth, but the treatment is not complete until the tooth is restored to a functional stage. This means a crown, or a similar type of dental work is necessary to make the tooth operational again.</p>
    <h3>Risks Associated With Root Canal Therapy</h3>
    <p>After completion of root canal therapy you might feel some discomfort for few days following the treatment. To alleviate the discomfort you can follow your dentist's recommendation on taking an over the counter pain medication. In more extreme cases your dentist may prescribe an antibiotic and prescription-strength pain reliever to help reduce any remaining infection.</p>
    <p>Following root canal therapy you should never chew directly on the repaired tooth until its final restoration has occurred or your tooth may crack. Also, keep in mind that the longer you wait to complete the final restoration the more likely bacteria will reinfect the treated canal requiring the therapy to be performed all over again.</p>
    <p>Root canal therapy, like every other treatment, is not free of unknowns and complications. There is a possibility that during the procedure a shaping file could break and get stuck in the root canal or that the root of the tooth fractures. In other cases a good seal may not be achieved due to the shape of the root. Lastly, it is possible to miss a hidden root or an extra canal that is in need of treatment. Of course these complications are the exceptions not the norm.</p>
    <p>In the event that root canal therapy is unsuccessful, Dr. Ghokale can discuss alternative options including repeating the treatment or extracting the infected tooth. Please contact Dr. Ghokale for &nbsp;Root Canal Treatment Sunnyvale CA.</p>`,
  },
  {
    name: "Sealants and Sportsguard",
    videoName: "PEDOSEAL.mp4",
    htmlText: `
    <h3>Sealants</h3>
    <p>During the first part of your check up we will look to see if sealants will be helpful
        to you. Sealants are protection for your teeth. Sealant is made from a plastic like material and is painted on
        the chewing surfaces of your teeth to protect them from cavities. If your dentist decides that sealants would
        help protect your teeth they will either decide to place the sealant on the first visit or schedule a follow up.
    </p>
    <h3>Mouth Guard or Sports Guard</h3>
    <p>You’ve probably seen others wearing a mouth guard during a game of football, basketball
        or hockey. If you play sports or if you’re just worried about injuring or knocking out your teeth, you can ask
        your dentist about a custom made mouth guard. A mouth guard will protect your teeth, lips, gums and cheeks from
        injury.</p>
    <p>A mouth guard is simple to make and it can be the difference between keeping your smile
        and repairing or replacing a broken tooth.</p>`,
  },
  {
    name: "Temporary Partial Denture",
    videoName: "STAYPLT.mp4",
    htmlText: `<div>
    <p>If you are scheduled to have a tooth pulled before getting your partial denture,
        then your dentist may advise you to get a temporary partial denture or a stayplate while your gums and their
        supporting bone are healing. A stayplate will replace the missing tooth or teeth and can help you with your
        chewing and speaking until a more permanent solution is achieved. A stayplate will also help maintain your
        appearance when in public and keep your existing teeth from shifting in your mouth and creating bigger
        problems.</p>
    <p><strong>The Stayplate Treatment</strong></p>
    <p>Stayplates are made using your mouth as a model. First, your dentist will take an
        accurate impression of the upper and lower arches of your mouth and establish a bite that best resembles
        your original bite. Your dentist will also help you select the shape and color of the stayplate teeth and
        gums. This impression is then sent to the dental laboratory.</p>
    <p>At the subsequent visit, the teeth will be removed and the stayplate will be
        delivered.</p>
    <p><strong>What You Should Know</strong></p>
    <p>Please keep in mind that there is no such a thing as a perfect stayplate. After
        delivery, it may require a few adjustment visits and some time for you and your stayplate to adapt to each
        other.</p>
    <p>Stayplates can also alter your eating; you will not have the same chewing
        efficiency as you had with your natural teeth. Stayplate will also alter your speaking and it may require a
        bit of practicing before you get comfortable.</p>
    <p>The most important point to remember is that adjusting to your stayplate is a
        process and stayplate is a temporary replacement until another form of treatment such as an implant, bridge
        or a partial denture can be made.</p>
</div>`,
  },
  {
    name: "Tooth Extraction",
    videoName: "EXTR.mp4",
  },
  {
    name: "Veneer",
    videoName: "VENE.mp4",
  },
  {
    name: "Wisdom Teeth",
    videoName: "WSDM.mp4",
  },
];
