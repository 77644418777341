import React from "react";

import "./CaseStudies.css";
import { Accordion, Container, Col, Row } from "react-bootstrap";

import CaseStudy1 from "../../images/caseStudy1.jpg";
import CaseStudy2 from "../../images/caseStudy2.jpg";
import CaseStudy3_1 from "../../images/Occlusal-Study-Model-CS3-1.jpg";
import CaseStudy3_2 from "../../images/Right-Side-Study-Model-CS3-2.jpg";
import CaseStudy3_3 from "../../images/Implant-Restored-with-Crown-CS3-3.jpg";
import CaseStudy3_4 from "../../images/before-implant-CS3-4.jpg";
import CaseStudy3_5 from "../../images/after-implant-CS3-5.jpg";

export default (props) => {
  console.log("Rendering Case Studies");

  return (
    <Container fluid="lg">
      <h1>Case Studies</h1>
      <Row>
        <Col>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Case Study 1: New Bridge</Accordion.Header>
              <Accordion.Body>
                <img src={CaseStudy1} alt="" className="case-study-image" />
                <p>
                  This patient came in with her bridge made in India several
                  years ago. She complained of food impaction and bad smell
                  under her bridge. On clinical exam and radiographs, the bridge
                  was found to be ill-fitting with leaky margins and recurrent
                  decay. The old bridge was removed, replaced with a temporary
                  bridge for 2 weeks and impressions with bite was taken and
                  sent to the Laboratory. Within 2 weeks she was happily smiling
                  with her beautiful bridge with absolutely no problems!!! After
                  2 weeks, the permanent crown was cemented and Raquel has not
                  stopped smiling since then!!! She is now planning to do
                  Invisalign to close spaces between her teeth.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                Case Study 2: Porcelain IPS Empress crown
              </Accordion.Header>
              <Accordion.Body>
                <img src={CaseStudy2} alt="" className="case-study-image" />
                <p>
                  Raquel came in with the front incisor with a large fractured
                  filling with recurrent decay. She was so embarrassed with her
                  smile that she refused to smile or hid her smile behind her
                  hands. After clinical exam and radiographs, she was advised an
                  all porcelain IPS Empress crown. The tooth was prepared for
                  the crown and lab technician came in to pick and match the
                  shade of the crown. Temporary crown was placed on the tooth.
                  After 2 weeks, the permanent crown was cemented and Raquel has
                  not stopped smiling since then!!! She is now planning to do
                  Invisalign to close spaces between her teeth.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                Case Study 3: Implant and Crown
              </Accordion.Header>
              <Accordion.Body>
                <section class="layout">
                  <div>
                    <img src={CaseStudy3_1} alt="" />
                    <p>Occlusal Study Model</p>
                  </div>
                  <div>
                    <img src={CaseStudy3_2} alt="" />
                    <p>Right Side Study Model</p>
                  </div>
                  <div>
                    <img src={CaseStudy3_3} alt="" />
                    <p>Implant Restored with Crown</p>
                  </div>
                  <div>
                    <img src={CaseStudy3_4} alt="" />
                    <p>Before Implant</p>
                  </div>
                  <div>
                    <img src={CaseStudy3_5} alt="" />
                    <p>After Implant</p>
                  </div>
                </section>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
    </Container>
  );
};
