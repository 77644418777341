import React from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import "./Contact.css";

export default (props) => {
  console.log("Rendering Contact");

  return (
    <Container style={{ paddingBottom: "15px" }}>
      <h1>Contact Us</h1>
      <Row>
        <Col xs={12} md={6}>
          <iframe
            title="Google Maps Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3171.2524827986995!2d-122.02827598387519!3d37.36020227983695!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fb5d155555555%3A0x6cd4d873687e980b!2sRemington%20Park%20Dental!5e0!3m2!1sen!2sus!4v1660302854449!5m2!1sen!2sus"
            scrolling="no"
            className="contact-map"
          ></iframe>
        </Col>
        <Col xs={12} md={6}>
          <h3>Office Hours</h3>
          <p>
            Monday - Saturday: 8am - 4pm <br />
            Sunday: Closed
          </p>
          <h3>Office Address</h3>
          <p>
            697 E. Remington Dr. Suite B
            <br />
            Sunnyvale, CA 94087
          </p>
          <h3>Phone</h3>
          <p>
            Tel <a href="tel:4082450600">(408) 245-0600</a>
          </p>
          <h3>Online Appointment Booking</h3>
          <Button
            style={{ fontFamily: "Josefin Sans, sans-serif" }}
            variant="primary"
            href="https://bookit.dentrixascend.com/soe/new/dental?pid=ASC13000000000822&amp;mode=externalLink"
            target="_blank"
          >
            Schedule Now
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
