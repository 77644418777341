import { Container, Col, Row } from "react-bootstrap";
import "./Testimonials.css";
export const videos = [
  { title: "Night Guard", url: "Jv1ikM61nwE" },
  { title: "Veneers", url: "nQ8iyX_ANXs" },
  {
    title: "Sherry Compares Her Experiences",
    url: "q3INfN_8FNM",
  },
  {
    title: "I Stopped Avoiding the Dentist",
    url: "nHP_aT5gkLQ",
  },
  { title: "Happy Teeth", url: "ZWmfb5TMLpw" },
  {
    title: "Root Canal? No Problem",
    url: "aceko4mncRs",
  },
  {
    title: "Dentist Recommended",
    url: "DvDtqLYgUU4",
  },
  {
    title: "Always a Good Experience at the Dentist's for Me",
    url: "zZ4L0HCa4yw",
  },
  {
    title: "I Finally Got My Wisdom Teeth Pulled",
    url: "fKpPYuRdRCc",
  },
  {
    title: "A Green Dental Practice",
    url: "z65Q8MaachQ",
  },
  {
    title: "Patient Interview— I'm OK with the Dentist!",
    url: "VdNNEyF2_Do",
  },
  {
    title: "Teeth Whitening for my Wedding",
    url: "DvMrE_cm4ZE",
  },
  {
    title: "Dentist Experience and a Paperless Office — Alexis",
    url: "ubu6LkKmvCk",
  },
  {
    title: "Doctor's Personal Trainer Interview",
    url: "p2s-uVTFpTQ",
  },
  {
    title: "Everyone's Scared of Needles, but...",
    url: "FI94n2aZmw4",
  },
  {
    title: "I Liked My Dentist's Music",
    url: "xfcKfoGbOaE",
  },
  {
    title: "An Affordable Dentist",
    url: "t_d7DnSyT74",
  },
  {
    title: "Dental Bridge Replacement",
    url: "ubfKt9Ayu7w",
  },
  {
    title: "She Interviewed Ten Dentists",
    url: "rdjprGJ4PmA",
  },
  {
    title: "Aurora Medina (Español)",
    url: "wzWE2ESZU5k",
  },
  {
    title: "Testimonial (Español)",
    url: "qwgQV0aohSc",
  },
  {
    title: "Testimonial (Español)",
    url: "MYSCmiQzHEI",
  },
  {
    title: "Testimonial (Español)",
    url: "suWDZBHlZww",
  },
  {
    title: "Testimonial (Español)",
    url: "8rbsVedUoao",
  },
];

export default () => {
  return (
    <Container>
      <Row>
        {videos.map((video, index) => (
          <Col key={video.title} sm={12} md={4} className="align-self-center">
            <h4>{video.title}</h4>
            <iframe
              src={`https://www.youtube.com/embed/${video.url}`}
              title={video.title}
              width="100%"
              allowFullScreen
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
};
