import React from "react";

import "./About.css";
import { Container, Col, Row } from "react-bootstrap";

export default (props) => {
  console.log("Rendering About");

  return (
    <Container fluid="lg">
      <h1>About the Doctor</h1>
      <Row>
        <Col xs={12} md={4}>
          <iframe
            src={`https://www.youtube.com/embed/JaPtsEK6ETs`}
            title="Sheetal Gokhale, DDS"
            width="100%"
            height={"360px"}
            allowFullScreen
          />
        </Col>
        <Col xs={12} md={8}>
          <h3>Dr. Sheetal Gokhale</h3>
          <p>
            An experienced dentist, Dr. Gokhale earned her Dental degree at the
            University of the Pacific, School of Dentistry in San Francisco. She
            has successfully completed the Dental Board Exam in California.
          </p>
          <p>
            Throughout her career, Dr. Gokhale has built her reputation as a
            skilled, caring and compassionate dentist, for who patient care and
            comfort is paramount. Her experience and multi-lingual staff create
            a warm, friendly environment and provide exceptional customer
            service. Before starting her practice, Dr. Gokhale has worked as an
            associate and managing dentist in several successful dental offices
            in the Bay Area.
          </p>
          <p>
            Dr. Gokhale volunteers regularly for Santa Clara Dental Society’s
            Give Kids A Smile Program which provides free dental treatment for
            underprivileged children. She resides in Cupertino with her husband
            and son. She enjoys reading, music, dance, and hiking during her
            free time.
          </p>
        </Col>
      </Row>
    </Container>
  );
};
